<template>
  <v-container fluid>
    <v-card class="mt-4 pa-2">
      <v-row>
        <v-col class="pl-8">
          <span>
            <b>{{$route.meta.headerTitle || ""}}</b>
          </span>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader>Lokasi Asal<span style="color:red;">*</span></v-subheader>
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              dense
              :loading="isLoadingGetLocations"
              :items="listLocations.filter((location) => location.id !== form.destinationLocationId) || []"
              item-value="id"
              item-text="name"
              v-model="form.originLocationId"
              :rules="rules.originLocationId"
              @input.native="getLocations"
              clearable
            > </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader>Lokasi Tujuan <span style="color:red;">*</span></v-subheader>
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              dense
              :loading="isLoadingGetLocations"
              :items="listLocations.filter((location) => location.id !== form.originLocationId) || []"
              item-value="id"
              item-text="name"
              v-model="form.destinationLocationId"
              :rules="rules.destinationLocationId"
              @input.native="getLocations"
              clearable
            > </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader>Estimasi Tiba (Hari)<span style="color:red;">*</span></v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              v-model="form.duration"
              :rules="rules.duration"
              clearable
            > </v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col>
            <v-btn elevation="2" small color="primary" class="float-right " @click="submit">
              <b>{{ $_strings.common.SAVE }}</b>
            </v-btn>
            <v-btn elevation="2" small class="mr-2 float-right" @click="$router.go(-1)">
              <b>{{ $_strings.common.BACK }}</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>

export default {
  // components: { CommonTextInput },
  name: 'etaCreateEdit',
  data() {
    return {
      valid: false,
      isLoadingGetLocations: false,
      listLocations: [],
      form: {
        originLocationId: null,
        destinationLocationId: null,
        duration: '',
        origin: '',
        destination: '',
      },
      rules: {
        originLocationId: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.eta.originLocationLabel),
        ],
        destinationLocationId: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.eta.destinationLocationLabel),
        ],
        duration: [
          (v) => {
            if (!v) return this.$_strings.messages.validation.REQUIRED(this.$_strings.eta.duration);
            if (+v === 0) return 'Harus lebih dari 0';
            return true;
          },
        ],
      },
    };
  },
  computed: {
    etaId() {
      return this.$route.params.id;
    },
    isEditing() {
      return !!this.etaId;
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    etaDetail() {
      this.$root.$loading.show();
      this.$_services.eta.etaDetail(this.$route.params.id)
        .then((res) => {
          this.form = {
            ...res.data,
          };
          this.listLocations.push({
            id: res.data.originLocationId,
            name: res.data.originLocationName,
          });
          this.listLocations.push({
            id: res.data.destinationLocationId,
            name: res.data.destinationLocationName,
          });
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    getLocations(event) {
      this.isLoadingGetLocations = true;
      const filters = {
        page: 0,
        size: 10,
      };
      if (event) {
        filters.name = event.target.value;
      }
      this.$_services.eta.getLocationList(filters)
        .then((res) => {
          this.listLocations = [...this.listLocations, ...res.data.contents];
        })
        .finally(() => {
          this.isLoadingGetLocations = false;
        });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      this.$root.$loading.show();
      this.form.duration = +this.form.duration;
      this.$_services.eta.etaCreate(this.form, this.etaId)
        .then((res) => {
          if (this.etaId) {
            this.$dialog.notify.success('Berhasil merubah ETA ');
          } else {
            this.$dialog.notify.success('Berhasil membuat ETA Baru');
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
          this.$router.push('/main/eta/list');
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.etaDetail();
    }
    this.getLocations();
  },
};
</script>

<style lang="scss" scoped>

</style>
